body {
  margin: 0px;
  padding: 0px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Helvetica Neue', 'HelveticaNeue-Light', 'Helvetica Neue Light',  
    Helvetica, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 
    'Droid Sans', Arial, Lucida Grande, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: '3px';
}

/* * {    
    margin: 0 !important;
    padding: 0 !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
    height: 8px;
    width: 8px;
    background: #F2F3F4;
    border: 0px solid #707070
}

::-webkit-scrollbar-thumb:horizontal{
    background: #707070;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb:vertical{
    background: #707070;
    border-radius: 15px;
}
