/* Header.js*/
.Header{
    background-color: #353B51; 
    padding: 10px 50px 10px 50px
}

.Title{
  text-align: center;
  color: #ffffff;
  margin-top: 0px;
  cursor: pointer;
}

.Button:hover{
  color: black
}

.AnyClassForContainer {
  position: fixed;
  right: -100px;
  bottom: 75px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: white;
  font-size: 20px;
}

.AnyClassForTransition {
  right: 20px;
}

.centered {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
}